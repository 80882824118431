<template>
  <div class="addPerson__wrap" v-if="visible">
    <el-header class="shadow page-header__wrap">
      <div class="flex">
        <div class="backBtn" @click="handleReturn">
          <el-image
            class="back-img"
            :src="require('@/assets/img/back.png')"
          ></el-image>
          <span>返回</span>
        </div>
        <div class="title">
          <span class="site">当前位置：</span>
          {{ $route.meta.title }}
          <span> > </span>
          分拣
        </div>
      </div>
    </el-header>

    <div class="main">
      <div class="mainBox" style="display: flex; flex-direction: column">
        <div>
          <el-row
            style="
              color: #606266;
              margin-bottom: 10px;
              font-size: 14px;
              font-weight: bold;
            "
          >
            <el-col :span="6">
              <span style="color: #909399">订单编号：</span
              >{{ currRow.order_no }}
            </el-col>
            <el-col :span="8">
              <div>
                <span style="color: #909399">客户名称：</span
                >{{ currRow.store_name }}（{{ currRow.merchant_name }}）
              </div>
            </el-col>
            <el-col :span="3">
              <div>
                <span style="color: #909399">商品项数：</span
                >{{ this.table.total }}
              </div>
            </el-col>
            <el-col :span="3">
              <div>
                <span style="color: #909399">商品数量：</span
                >{{ currRow.sum_quantity }}
              </div>
            </el-col>
          </el-row>
          <el-form
            ref="elFormDom"
            inline
            :model="table.params"
            :size="subUnitSize"
            style="margin-bottom: 10px"
          >
            <el-form-item label="商品名称" prop="goods_name">
              <el-input
                v-model.trim="table.params.goods_name"
                clearable
                placeholder="请输入"
                @keydown.enter.native="getTable"
                @clear="getTable"
              ></el-input>
            </el-form-item>
            <el-form-item label="下单分类" prop="order_cate_id">
              <el-select
                v-model="table.params.order_cate_id"
                filterable
                placeholder="请选择"
                clearable
                @focus="getOrderCateSel"
                @change="getTable"
              >
                <el-option
                  v-for="item in orderCateArr"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="供应商/采购员" prop="supplier_id">
              <el-select
                v-model="table.params.supplier_id"
                filterable
                id="bind_id"
                clearable
                placeholder="请输入"
                @change="onSearch"
              >
                <el-option
                  v-for="item in supplierArr"
                  :label="item.name"
                  :value="item.id"
                  :key="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button
                type="primary"
                icon="el-icon-search"
                @click="getTable(currRow, currType)"
                >搜索</el-button
              >
              <el-button icon="el-icon-refresh-right" @click="reset"
                >重置</el-button
              >
            </el-form-item>
          </el-form>
          <div class="btnAdd" style="margin-bottom: 10px">
            <el-radio-group
              v-model="selectAction"
              :size="subUnitSize"
              class="sortingRadioBtn"
              @change="handleBatch"
            >
              <el-radio-button :label="1">批量完成</el-radio-button>
              <el-radio-button :label="2">批量部分完成</el-radio-button>
              <el-radio-button :label="3">批量标记缺货</el-radio-button>
              <el-radio-button :label="4">批量打印</el-radio-button>
              <el-radio-button :label="5">批量修改分拣员</el-radio-button>
            </el-radio-group>
          </div>
        </div>
        <div ref="tableDom" style="flex: 1">
          <el-table
            ref="multipleTable"
            :data="tableData"
            style="width: 100%"
            border
            :height="height"
            :header-cell-style="{ background: '#f5f5f5' }"
            @selection-change="handleSelectionChange"
          >
            <el-table-column
              v-if="is_showSelect"
              type="selection"
              width="55"
              :selectable="selectEnable"
            >
            </el-table-column>
            <el-table-column type="index" label="ID"></el-table-column>
            <el-table-column prop="good_name" label="商品名称" width="180px">
              <template slot-scope="{ row }">
                <div>{{ row.good_name }}</div>
                <!-- 标准名称和商品名称相同时不显示标准名称 -->
                <div
                  v-if="!!row.norm_name && row.norm_name != row.good_name"
                  style="font-size: 12px; color: #ff5b5b"
                >
                  ({{ row.norm_name }})
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="order_cate_name"
              label="下单分类"
              min-width="120px"
            >
              <template slot-scope="{ row }">
                <span>{{ row.order_cate_name }}</span>
                <span v-if="!!row.order_tow_name">
                  / {{ row.order_tow_name }}</span
                >
              </template>
            </el-table-column>
            <el-table-column prop="spec_name" label="规格"> </el-table-column>
            <el-table-column prop="spec_unit_name" label="分拣单位">
            </el-table-column>
            <el-table-column prop="quantity" label="下单数量">
            </el-table-column>
            <!-- 已分拣数量 (是否超标 1:是  2:否,超标字体标红)-->
            <el-table-column prop="sorter_num" label="已分拣数量">
              <template slot-scope="{ row }">
                <div :class="row.is_notice == 1 ? 'redColor' : ''">
                  {{ row.sorter_num }}
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="remark" label="备注"></el-table-column>
            <el-table-column prop="num" label="*分拣数量" width="150px">
              <template slot-scope="{ row, $index }">
                <!-- lack_num > 0 就是缺货, 分拣状态：sorter_status:1待分拣，2：部分分拣，3：分拣完成，订单状态已取消时也不显示输入框 -->
                <span
                  v-if="
                    row.lack_num > 0 ||
                    row.sorter_status == 3 ||
                    row.order_status == 4
                  "
                ></span>
                <template v-else>
                  <el-input
                    v-model="row.num"
                    placeholder="请输入"
                    size="small"
                    @input="countPrice(row, $index)"
                    @keydown.enter.native="handeSorter(row, 2)"
                  ></el-input>
                  <div style="color: red" v-if="row.is_exceed">超标</div>
                </template>
              </template>
            </el-table-column>
            <el-table-column prop="sorter_status" label="分拣状态" width="100">
              <template slot-scope="{ row }">
                <!-- 订单状态已取消，分拣状态就显示空 -->
                <span v-if="row.order_status == 4"></span>
                <span v-else>{{
                  toStr(
                    { 1: "待分拣", 2: "部分分拣", 3: "分拣完成" },
                    row.sorter_status
                  )
                }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="sorter_name" label="分拣员" fixed="right">
              <template slot-scope="{ row }">
                <!--订单状态待分拣 且 分拣状态：待分拣和部分分拣可以修改分拣员 -->
                <el-button
                  v-if="row.order_status == 0 && row.sorter_status != 3"
                  type="text"
                  @click="showEdit(row)"
                  >{{ row.sorter_name ? row.sorter_name : "未绑定" }}</el-button
                >
                <span v-else type="text">{{ row.sorter_name }}</span>
              </template>
            </el-table-column>
            <!-- <el-table-column
              prop="status"
              label="订单状态"
              width="100">
              <template slot-scope="{row}">{{toStr({0:'待分拣',1:'待配送', 2:'待送达', 3:'已完成', 4:'已取消'},row.order_status)}}</template>
            </el-table-column> -->
            <el-table-column
              prop="action"
              label="操作"
              width="260px"
              fixed="right"
            >
              <template slot-scope="{ row }">
                <!-- 订单状态order_status：已完成之后没有操作功能-->
                <template v-if="row.status < 3">
                  <template v-if="row.sorter_status != 3">
                    <el-button
                      type="text"
                      slot="reference"
                      :style="{ color: row.lack_num == 0 ? '' : '#ff6600' }"
                      @click="handleCancelLack(row)"
                      >{{
                        row.lack_num == 0 ? "标记缺货" : "取消缺货"
                      }}</el-button
                    >
                  </template>
                  <!-- 待分拣展示： 分拣完成、 部分分拣 -->
                  <template v-if="row.sorter_status != 3">
                    <el-button
                      type="text"
                      slot="reference"
                      @click="handeSorter(row, 2)"
                      >分拣完成</el-button
                    >
                    <!-- 缺货不可部分分拣操作 -->
                    <el-button
                      v-if="row.lack_num == 0"
                      type="text"
                      slot="reference"
                      @click="handeSorter(row, 1)"
                      >部分分拣</el-button
                    >
                  </template>
                  <!-- 配送状态 distr_status 等于0或1時可以重置，其他状态不能重置 -->
                  <template
                    v-if="
                      row.sorter_status != 1 &&
                      (row.distr_status == 0 || row.distr_status == 1)
                    "
                  >
                    <el-button
                      type="text"
                      slot="reference"
                      @click="handleReset(row)"
                      >重置</el-button
                    >
                  </template>
                  <el-button
                    type="text"
                    slot="reference"
                    v-print="'#myPrint'"
                    @click="handlePrint(row)"
                    >打印</el-button
                  >
                  <!-- 订单状态status：1:'待确认', 2:'待收货', 3:'已完成', 4:'已取消', 5:'退款' -->
                </template>
                <el-button type="text" v-if="row.order_status > 0 && row.order_status < 4" @click="handleNume(row)">
                  修改分拣数量
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div class="footerWrap" v-if="multipleSelection.length > 0">
        <el-button
          type="primary"
          size="medium"
          icon="el-icon-check"
          @click="handleConfirm"
          >选好了</el-button
        >
      </div>
    </div>

    <!-- 批量打印提示弹窗 -->
    <el-dialog
      title="确认打印标签吗？"
      :visible.sync="dialogVisiblePrint"
      width="450px"
      :before-close="handleClose"
      append-to-body
      center
    >
      <div>
        标签数量：
        <span style="font-weight: bold">{{ multipleSelection.length }}</span>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          v-print="'myPrintClientBatch'"
          @click="handlePrintbatchFun"
          >确 定</el-button
        >
      </span>
    </el-dialog>

    <!-- 单条打印区域-->
    <div id="myPrint">
      <div class="printContent">
        <div class="print-title" style="padding-bottom: 5px">
          {{ currRow.store_name }}
        </div>
        <div class="print-flex">
          <div>
            <div class="print-con" style="margin-bottom: 2x">
              <span>{{ currPrintObj.good_name }}</span>
            </div>
            <div class="print-con">
              下单数量：{{ currPrintObj.quantity
              }}{{ currPrintObj.spec_unit_name }}
            </div>
            <!-- 分拣数量：待分拣时取下单数量，部分分拣或分拣完成取已分拣数量 -->
            <div class="print-con">
              分拣数量：{{
                currPrintObj.sorter_status == 1
                  ? currPrintObj.quantity
                  : currPrintObj.sorter_num
              }}{{ currPrintObj.spec_unit_name }}
            </div>
          </div>
          <div>
            <div class="qrcode" ref="qrCodeUre"></div>
          </div>
        </div>
        <div class="print-text" style="margin-left: 5px; margin-top: 2px">
          备注：{{ currPrintObj.remark }}
        </div>
        <div class="print-text" style="text-align: right; margin-top: 2px">
          {{ $moment(new Date()).format("yyyy-MM-DD HH:mm:ss") }}
        </div>
      </div>
    </div>

    <!-- 修改分拣员 -->
    <SorterEdit
      ref="sorterEdit"
      @refresh="getDetail(currRow, currType)"
    ></SorterEdit>
    <NumEdit
      ref="numEdit"
      @refresh="getDetail(currRow, currType)"
    ></NumEdit>

    <!-- 批量打印区域 -->
    <div id="myPrintClientBatch">
      <div
        class="printContent"
        v-for="(item, index) in multipleSelection"
        :key="index"
        style="pading-left: 15px"
      >
        <div class="print-title">
          {{ currRow.store_name }}
        </div>
        <div class="print-flex">
          <div>
            <div class="print-con" style="margin-bottom: 10px">
              <span>{{ item.good_name }}</span>
            </div>
            <div class="print-con">
              下单数量：{{ item.quantity }}{{ item.spec_unit_name }}
            </div>
            <div class="print-con">
              分拣数量：{{
                item.sorter_status == 1 ? item.quantity : item.sorter_num
              }}{{ item.spec_unit_name }}
            </div>
          </div>
          <div>
            <div class="qrcode" ref="qrCodeUreBatch"></div>
          </div>
        </div>
        <div class="print-text" style="margin-left: 5px; margin-top: 2px">
          备注：{{ item.remark }}
        </div>
        <div
          class="print-text"
          style="text-align: right; margin-top: 2px; margin-bottom: 20px"
        >
          {{ $moment(new Date()).format("yyyy-MM-DD HH:mm:ss") }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { v4 as uuid } from "uuid";
import QRCode from "qrcodejs2";
import SorterEdit from "./SorterEdit.vue";
import NumEdit from "./NumEdit.vue";
import { mixinTable } from "@/mixins/table.js";
export default {
  name: "SortingGlientSorting",
  mixins: [mixinTable],
  components: {
    SorterEdit,
    NumEdit
  },
  data() {
    return {
      height: 0,
      selectAction: 0, // 批量操作选项
      myPrint: {
        id: "myPrint",
        preview: false, // 是否启动预览模式，默认是false（开启预览模式，可以先预览后打印）
      },
      myPrintClientBatch: {
        id: "myPrintClientBatch",
        preview: false, // 是否启动预览模式，默认是false（开启预览模式，可以先预览后打印）
      },
      currPrintObj: {},
      subUnitSize: this.$store.state.subUnitSize, // 获取组件 size属性
      visible: false,
      orderCateArr: [],
      tableData: [],
      table: {
        params: {
          page: 1,
          count: 10,
          type: 2,
          date: "",
          order_id: "", // 按客户分类，order_id订单id必传
          goods_name: "",
          order_cate_id: "",
          supplier_id: "",
        },
        total: 0,
      },
      supplierArr: [], // 供应商/采购员
      loading: false,
      multipleSelection: [],
      currRow: {},
      currType: "",
      myUUID: "", // 存放生成的UUID值
      is_showSelect: false,
      dialogVisiblePrint: false, //批量打印提示弹窗
    };
  },
  methods: {
    getDetail(row, currType, supplier_id) {
      this.currRow = row;
      this.currType = currType; // 1:按商品分拣，2：按客户分拣
      this.table.params.order_id = row.order_id; // 订单id
      this.table.params.supplier_id = supplier_id; // 订单id
      this.table.params.date = this.$moment(row.distr_date).format(
        "YYYY-MM-DD"
      ); // 订单配送日期
      this.table.params.type = currType; // 1:商品 2:客户
      this.getTable();
      this.getSupplierSel();
      this.$nextTick(() => {
        this.height = this.$refs.tableDom.offsetHeight || 500;
      });
    },
    getTable() {
      this.$http
        .get("/admin/sorter_order/info", { params: this.table.params })
        .then((res) => {
          if (res.code == 1) {
            this.table.total = res.data.total;
            this.tableData = res.data.list.map((v) => {
              return {
                num: "",
                is_exceed: false, // 输入分拣数量后计算是否会超标
                ...v,
              };
            });
          }
        });
    },
    handleClose() {
      this.dialogVisiblePrint = false;
    },
    reset(done) {
      done && done();
    },
    toggle(show) {
      this.visible = show;
      !show && this.reset();
    },
    // 单条收货 1:部分分拣 2:全部分拣
    handeSorter(row, type) {
      if (!row.num && type == 1) {
        this.$message.warning("请输入分拣数量");
        return;
      }
      let str = type == 1 ? "部分分拣" : "全部分拣";
      this.$confirm(`请确认分拣操作`, `${str}`, {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      })
        .then(() => {
          let _params = {
            type: type,
            num: row.num ? row.num : '',
            detail_id: row.id,
          };
          this.$http.post("/admin/sorter_order/sorter", _params).then((res) => {
            if (res.code == 1) {
              this.$message.success(`操作成功！`);
              this.getTable();
            }
          });
        })
        .catch(() => {});
    },
    // 计算分拣数量
    countNum(arr) {
      let sum = 0;
      arr.map(function (item) {
        sum += Number(item.quantity - item.sorter_num);
      });
      return sum;
    },
    handleBatch() {
      this.is_showSelect = true;
      this.multipleSelection = [];
      this.$refs.multipleTable.clearSelection(); // 清空用户的选择
    },
    // 选好了确认
    handleConfirm() {
      // selectAction 1:批量完成 2:批量部分完成 3:批量标记缺货 4:批量打印
      if (this.selectAction == 1) {
        let _spdetail_listec = this.multipleSelection.map((v) => {
          return {
            detail_id: v.id, //明细ID
            num: !!v.num ? v.num : '',
          };
        });
        let _params = {
          type: 2, //类型 1:部分分拣 2:全部分拣
          detail_list: _spdetail_listec,
        };
        let num = this.countNum1(this.multipleSelection); // 计算分拣总数量
        this.$confirm(
          `本次分拣完成：商品项数：${this.multipleSelection.length} ,  分拣数量：${num}`,
          "确认分拣完成吗",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
            center: true,
          }
        ).then(() => {
          this.$http
            .post("/admin/sorter_order/allSorter", _params)
            .then((res) => {
              if (res.code == 1) {
                this.$message.success("操作成功！");
                this.getTable();
              }
            });
        });
      } else if (this.selectAction == 2) {
        //批量部分完成
        let is_num = this.multipleSelection.some((item) => !item.num); // 检测提交的数据是否填了分拣数量
        if (is_num) {
          this.$message.warning("请输入分拣数量！");
          return;
        }
        let _spdetail_listec = this.multipleSelection.map((v) => {
          return {
            detail_id: v.id, //明细ID
            num: v.num,
          };
        });
        let _params = {
          type: 1, //类型 1:部分分拣 2:全部分拣
          detail_list: _spdetail_listec,
        };
        this.$http
          .post("/admin/sorter_order/allSorter", _params)
          .then((res) => {
            if (res.code == 1) {
              this.$message.success("操作成功！");
              this.getTable();
            }
          });
      } else if (this.selectAction == 3) {
        // 批量标记缺货
        let num = this.countNum(this.multipleSelection);
        this.$confirm(`缺货数量：${num}`, "确定标记缺货吗", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
          center: true,
        })
          .then(() => {
            let ids = this.multipleSelection
              .map((v) => {
                return v.id;
              })
              .join("|");
            this.$http
              .post("/admin/sorter_order/lack", { detail_ids: ids, status: 1 })
              .then((res) => {
                if (res.code == 1) {
                  this.$message.success("操作成功！");
                  this.getTable();
                }
              });
          })
          .catch(() => {});
      } else if (this.selectAction == 4) {
        //批量打印
        this.dialogVisiblePrint = true;
      } else if (this.selectAction == 5) {
        // 批量修改分拣员
        let dom = this.$refs.sorterEdit;
        dom.toggle(true);
        dom.getDetail(this.multipleSelection, 2); //type:1单条修改，2批量修改分拣员工
        dom = null;
      }
    },

    //全部分拣完成计算分拣数量： 如果输入了分拣数量num就取分拣数量，否则判断已分拣数量sorter_num如果等于零的话取下单数量quantity，否则取已分拣数量sorter_num
    countNum1(arr) {
      let sum = 0;
      arr.map(function (item) {
        sum += item.num !== "" ? Number(item.num) : (Number(item.sorter_num) > 0 ? 0 : Number(item.quantity))
        // sum += !!Number(item.num)
        //   ? Number(item.num)
        //   : Number(item.sorter_num) == 0
        //   ? Number(item.quantity)
        //   : Number(item.sorter_num);
      });
      return sum;
    },
    // 全部分拣完成
    confirm() {
      if (this.multipleSelection.length > 0) {
        let _spdetail_listec = this.multipleSelection.map((v) => {
          return {
            detail_id: v.id, //明细ID
            num: !!v.num ? v.num : '',
          };
        });
        let _params = {
          type: 2, //类型 1:部分分拣 2:全部分拣
          detail_list: _spdetail_listec,
        };
        let num = this.countNum1(this.multipleSelection); // 计算分拣总数量
        this.$confirm(
          `本次分拣完成：商品项数：${this.multipleSelection.length} ,  分拣数量：${num}`,
          "确认分拣完成吗",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
            center: true,
          }
        ).then(() => {
          this.$http
            .post("/admin/sorter_order/allSorter", _params)
            .then((res) => {
              if (res.code == 1) {
                this.$message.success("操作成功！");
                this.getTable();
              }
            });
        });
      } else {
        this.$message.warning("请勾选商品数据！");
      }
    },
    selectEnable(row) {
      if (this.selectAction == 4) {
        // this.selectAction == 4打印时不需要禁用
        return true;
      } else if (
        row.lack_num == 0 &&
        row.sorter_status != 3 &&
        row.status < 4
      ) {
        // lack_num > 0 缺货的 和 分拣完成的,订单已取消的不可以选，禁用勾选框
        return true;
      }
    },
    // 返回
    handleReturn() {
      this.$emit("refresh");
      this.toggle(false);
      this.multipleSelection = [];
      this.is_showSelect = false;
      this.selectAction = 0;
    },
    // 标记缺货、取消标记缺货
    handleCancelLack(row) {
      let str = row.lack_num == 0 ? "标记缺货" : "取消标记";
      this.setConfirm(
        `确定${str}吗？`,
        { detail_ids: row.id, status: row.lack_num == 0 ? 1 : 2 },
        "/admin/sorter_order/lack"
      );
    },
    // 清空查询条件
    reset() {
      this.table.params = {
        sorter_id: "",
        order_id: "",
        sorter_status: "",
        page: 1,
        count: 10,
      };
      console.log(this.currType);
      this.getDetail(this.currRow, this.currType);
    },
    // 重置分拣
    handleReset(row) {
      this.setConfirm(
        `请确认重置操作？`,
        { detail_id: row.id },
        "/admin/sorter_order/reset"
      );
    },
    // 修改分拣员
    showEdit(row) {
      let dom = this.$refs.sorterEdit;
      dom.toggle(true);
      dom.getDetail(row, 1); //type:1单条修改，2批量修改
      dom = null;
    },
    // 单条打印
    handlePrint(row) {
      this.currPrintObj = row;
      this.myUUID = uuid().replace(/-/g, "/"); // myUUID将字符串中的 '-' 替换成 '/'
      // takeNum分拣数量：待分拣时取下单数量，部分分拣或分拣完成取已分拣数量
      var takeNum = row.sorter_status == 1 ? row.quantity : row.sorter_num;
      this.$refs["qrCodeUre"].innerHTML = ""; //生成二维码之前先清除上一次的
      new QRCode(this.$refs.qrCodeUre, {
        text: `CODE_JSZ_JIN_CAI_ID-${row.order_no}-${row.id}-${takeNum}-${this.myUUID}`,
        width: 70,
        height: 70,
        colorDark: "#000",
        colorLight: "#fff",
        correctLevel: QRCode.CorrectLevel.L,
      });
      setTimeout(() => {
        this.myPrint = {
          id: "myPrint",
          preview: false, // 是否启动预览模式，默认是false（开启预览模式，可以先预览后打印）
        };
      }, 1000);
    },
    handlePrintbatchFun() {
      this.dialogVisiblePrint = false;
      this.is_showSelect = false;
      this.multipleSelection.forEach((v, i) => {
        this.currPrintObj = v;
        this.myUUID = uuid().replace(/-/g, "/"); // myUUID将字符串中的 '-' 替换成 '/'
        // takeNum分拣数量：待分拣时取下单数量，部分分拣或分拣完成取已分拣数量
        var takeNum = v.sorter_status == 1 ? v.quantity : v.sorter_num;
        this.$refs["qrCodeUreBatch"][i].innerHTML = ""; //生成二维码之前先清除上一次的
        new QRCode(this.$refs.qrCodeUreBatch[i], {
          text: `CODE_JSZ_JIN_CAI_ID-${v.order_no}-${v.id}-${takeNum}-${this.myUUID}`,
          width: 70,
          height: 70,
          colorDark: "#000",
          colorLight: "#fff",
          correctLevel: QRCode.CorrectLevel.L,
        });
      });

      this.myPrintClientBatch = {
        id: "myPrintClientBatch",
        popTitle: "打印", // 打印配置页上方标题
        extraHead: "", //最上方的头部文字，附加在head标签上的额外标签,使用逗号分隔
        preview: true, // 是否启动预览模式，默认是false（开启预览模式，可以先预览后打印）
      };
    },
    // 批量打印
    handlePrintBatch() {
      this.$message.warning("请至少选择一条数据");
    },
    // 计算输入的收货数量是否超标
    countPrice(row, index) {
      const reg = /^[\d]+$/;
      if (row.num && !reg.test(row.num)) {
        row.num = row.num.replace(/[^0-9.]/g, "");
      }
      // 已分拣数量+本次输入分拣数量大于预警值就要提示超标
      if (Number(row.sorter_num) + Number(row.num) > Number(row.notice_num)) {
        row.is_exceed = true; // 输入的收货数量是否超标
        this.$set(this.tableData, index, row);
      } else {
        row.is_exceed = false; // 输入的收货数量是否超标
        this.$set(this.tableData, index, row);
      }
    },
    handleNume(row) {
      let dom = this.$refs.numEdit;
      dom.toggle(true);
      dom.getDetail(row);
      dom = null;
    }
  },
};
</script>


<style scoped lang="scss">
.addPerson__wrap {
  @include position($t: 0, $r: 0, $b: 0, $l: 0);
}
.addPerson__wrap .main {
  overflow-x: hidden;
  background: #fff;
}
.returnBtn {
  z-index: 99;
  box-shadow: 0 0 8px 0 rgb(232 237 250 / 60%),
    0 2px 4px 0 rgb(232 237 250 / 50%);
}
.footerWrap {
  text-align: center;
  padding: 10px;
  box-shadow: 0 0 8px 0 rgb(232 237 250 / 60%),
    0 2px 4px 0 rgb(232 237 250 / 50%);
  z-index: 99;
}
.itemTime {
  display: block;
  margin-bottom: 10px;
  height: 30px;
  line-height: 30px;
}
.itemTime::before {
  content: "";
  width: 5px;
  height: 15px;
  background: #2cb167;
  border-radius: 6px;
  display: inline-block;
  margin-right: 10px;
  position: relative;
  top: 2px;
}
.flex {
  display: flex;
}
.mt20 {
  margin-top: 20px;
}
.mb20 {
  margin-bottom: 20px;
}
.width200px {
  width: 200px;
}
.after-table {
  padding: 10px;
  font-size: 13px;
  color: #303030;
  text-align: right;
}
</style>
<style scoped lang="scss">
.sortingRadioBtn {
  &::v-deep {
    .el-radio-button:first-child .el-radio-button__inner {
      border-radius: 4px !important;
    }
    .el-radio-button:last-child .el-radio-button__inner {
      border-radius: 4px !important;
    }
    .el-radio-button {
      margin-right: 10px !important;
    }
    .el-radio-button__inner {
      border: 1px solid #abe0c2 !important;
      border-radius: 4px;
      color: #2cb167;
      background: #eaf7f0;
    }
    .el-radio-button__inner:hover {
      color: #fff;
      background: #2cb167;
    }
    .el-radio-button.is-active .el-radio-button__inner {
      color: #fff;
      background: #2cb167;
    }
  }
}
</style>
