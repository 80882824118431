<template>
  <el-dialog
    append-to-body
    width="450px"
    title="修改分拣数量"
    :visible.sync="visible"
    :before-close="reset"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    >
    <el-form
      v-if="visible"
      class="staff-from"
      v-loading="loading"
      element-loading-text="加载中…"
      ref='elFormDom'
      label-width='100px'
      label-position='right'
      :model='form.data'
      :rules='form.rules'
      :size="subUnitSize"
    >
      <el-form-item label="分拣数量" prop="num">
        <el-input v-model="form.data.num" placeholder="请输入" @input="form.data.num=form.data.num.match(/\d+(\.\d{0,2})?/) ? form.data.num.match(/\d+(\.\d{0,2})?/)[0] : ''"></el-input>
      </el-form-item>
      <el-form-item label="选择仓库" prop="stash_id" v-if="!form.data.stash_id">
        <el-select v-model="form.data.stash_id">
          <el-option v-for="item in stashArr" :label="item.name" :value="item.id" :key="item.id">
            <span style="float: left">{{ item.name }}</span>
            <span style="float: right; color: #8492a6; font-size: 13px">{{ item.province_str}}{{item.city_str}}</span>
          </el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <template #footer>
      <el-row type="flex" justify="end">
        <el-button @click='toggle(false)'>取消修改</el-button>
        <el-button type="primary" :loading="form.loading" :disabled="form.loading" @click='confirm'>确认修改</el-button>
      </el-row>
    </template>
  </el-dialog>
</template>

<script>
  export default {
    name: 'NumEdit',
    data() {
      return {
        subUnitSize: this.$store.state.subUnitSize, // 获取组件 size属性
        visible: false,
        isChange: false,
        loading: false,
        form: {
          loading: false,
          data: {
            num: '',
            detail_id: '',
            stash_id: '',
          },
          rules: {
            num: [{ required: true, message:'必填项', trigger: ['change','blur']}],
            stash_id: [{ required: true, message:'必填项', trigger: ['change','blur']}],
          }
        },
        stashArr: [],
        city: 0
      }
    },
    created(){
      this.getStash();
    },
    methods: {
      getDetail(row) {
        this.form.data.detail_id = row.id;
        this.sorter_num = row.sorter_num;
        setTimeout(() => {
          let curr_stash_id = this.stashArr.filter(v => v.city == row.city); // 过滤出供应商地区的仓库
          if(curr_stash_id.length == 1) {
            this.form.data.stash_id =  curr_stash_id[0].id;
          } else {
            this.form.data.stash_id = '';
          }
        }, 300);
      },
      reset(done) {
        this.isChange = false
        this.$refs.elFormDom.resetFields()
        done && done()
      },
      toggle(show) {
        this.visible = show
        !show && this.reset()
      },
      confirm() {
        this.$refs.elFormDom.validate(valid => {
          if (valid) {
            if(this.sorter_num == this.form.data.num) {
              return this.$message.warning("分拣数量无变化")
            }
            let _param = {};
            _param = {
              num: this.form.data.num,
              detail_id: this.form.data.detail_id,
              stash_id: this.form.data.stash_id,
            }
            this.form.loading = true;
            this.$http.post('/admin/sorter_order/updateNum', _param).then(res => {
              if(res.code === 1) {
                this.$message.success('操作成功！')
                this.toggle(false);
                this.$emit('refresh')
              }
            }).finally(() => {
              this.form.loading = false;
            })
          }
        })
      },
      getStash() {
        // status 状态 1:启用 2:禁用
        this.$http.get('/admin/stash/list', { params:{ page: 1,count: 10000, status:1}}).then(res => {
          if(res.code == 1) {
            this.stashArr = res.data.list;
          }
        })
      },
    }
  }
</script>

<style scoped>
.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
  /* .idPhoto .avatar{
    width: 100% !important;
  } */
  .idPhoto .avatar-uploader-icon {
    width: 178px !important;
    height: 178px !important;
    line-height: 178px !important;
    border: 1px dashed #d9d9d9;
  }
  .onBtn-large {
    display: flex;
    justify-content: center;
  }
</style>
